import type { LinkProps, NavLinkProps } from "@remix-run/react";
import { Link, NavLink } from "@remix-run/react";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import {
  useShortcutKeys,
  type ShortcutDefinition,
} from "~/hooks/useShortcutKeys";
import { cn } from "~/utils/cn";
import { ShortcutKey } from "./ShortcutKey";

const variant = {
  "primary/small": {
    textStyling:
      "text-sm font-medium group-hover:text-charcoal-800 text-charcoal-900 group-disabled:text-dimmed/80",
    buttonStyling:
      "h-7 px-[7px] rounded-sm duration-500 bg-primary hover:bg-primary-hover disabled:opacity-50 text-xs",
    icon: "h-3.5 text-charcoal-900",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "secondary/small": {
    textStyling:
      "text-sm font-medium text-lavender-400 group-hover:text-lavender-300",
    buttonStyling:
      "h-7 bg-background px-[7px] rounded-sm disabled:opacity-50 border-lavender-400 border group-hover:border-lavender-300",
    icon: "h-3.5 text-lavender-400 group-hover:text-lavender-300",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "tertiary/small": {
    textStyling:
      "text-charcoal-300 group-hover:text-bright text-sm font-medium group-disabled:text-dimmed/80",
    buttonStyling:
      "h-7 px-[7px] rounded border-charcoal-600 bg-background border hover:bg-grid-dimmed",
    icon: "h-3.5",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "minimal/small": {
    textStyling:
      "text-charcoal-300 text-sm font-medium group-hover:text-bright group-disabled:text-dimmed/80",
    buttonStyling: "h-7 px-[7px]",
    icon: "h-3.5 text-charcoal-300 group-hover:text-bright",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "primary/medium": {
    textStyling:
      "text-background group-disabled:text-dimmed/80 text-sm font-medium",
    buttonStyling:
      "h-8 px-2.5 rounded bg-primary hover:bg-primary-hover disabled:opacity-50 group-disabled:bg-charcoal-700",
    icon: "h-4 text-charcoal-900",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "secondary/medium": {
    textStyling:
      "text-base font-medium text-lavender-400 group-hover:text-lavender-300",
    buttonStyling:
      "h-8 px-3 bg-background rounded disabled:opacity-50 border-lavender-400 border group-hover:border-lavender-300",
    icon: "h-4 text-lavender-400 group-hover:text-lavender-300",
    iconSpacing: "gap-x-1.5",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "minimal/medium": {
    textStyling:
      "text-charcoal-300 text-sm font-medium group-hover:text-bright group-disabled:text-dimmed/80",
    buttonStyling: "h-8 px-3",
    icon: "h-4 text-charcoal-300 group-hover:text-bright",
    iconSpacing: "gap-x-1.5",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "tertiary/medium": {
    textStyling:
      "text-charcoal-300 group-hover:text-bright text-sm group-disabled:text-dimmed/80",
    buttonStyling:
      "h-8 px-3 bg-background rounded border-charcoal-600 border-[0.5px] hover:bg-grid-dimmed",
    icon: "h-4",
    iconSpacing: "gap-x-1.5",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "primary/large": {
    textStyling:
      "group-hover:text-charcoal-800 text-charcoal-900 group-disabled:text-dimmed/80 text-lg font-medium",
    buttonStyling:
      "h-10 px-4 rounded bg-primary hover:bg-primary-hover disabled:opacity-50",
    icon: "h-5 text-charcoal-900",
    iconSpacing: "gap-x-2",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "secondary/large": {
    textStyling:
      "text-lg font-medium text-lavender-400 group-hover:text-lavender-300",
    buttonStyling:
      "h-10 px-4 bg-background rounded disabled:opacity-50 border-lavender-400 border group-hover:border-lavender-300",
    icon: "h-5 text-lavender-400 group-hover:text-lavender-300",
    iconSpacing: "gap-x-2",
    shortCutClassName: "",
    shortcutVariant: "medium" as const,
    shortcutStyling:
      "ml-1.5 -mr-0. border-lavender-400 text-lavender-400 group-hover:border-lavender-300 no-underline",
  },
  "tertiary/large": {
    textStyling:
      "text-charcoal-300 group-hover:text-bright text-lg font-medium group-disabled:text-dimmed/80",
    buttonStyling:
      "h-10 px-4 bg-background rounded border-charcoal-600 border hover:bg-grid-dimmed",
    icon: "h-5",
    iconSpacing: "gap-x-2",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "menu-item": {
    textStyling: "text-dimmed group-hover:text-bright text-base font-normal",
    buttonStyling:
      "h-12 px-[5px] disabled:opacity-50 text-xs border-b border-charcoal-700 ",
    icon: "h-5",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "nav-item": {
    textStyling: "text-bright text-sm group-hover:text-apple-400",
    buttonStyling: undefined,
    icon: "h-4",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
  "mobile-nav-item": {
    textStyling:
      "text-bright text-left group-hover:text-bright text-base font-normal",
    buttonStyling:
      "disabled:opacity-50 text-xs border-b border-grid-dimmed py-3",
    icon: "h-5",
    iconSpacing: "gap-x-1",
    shortcutVariant: undefined,
    shortcut: undefined,
    shortcutStyling: undefined,
  },
};

const allVariants = {
  $all: "text-center font-sans transition justify-center items-center shrink-0 select-none group-focus:outline-none group-disabled:opacity-75 group-disabled:pointer-events-none",
  variant: variant,
};

type ButtonContentPropsType = {
  children?: React.ReactNode;
  LeadingIcon?: React.ComponentType<any>;
  TrailingIcon?: React.ComponentType<any>;
  trailingIconClassName?: string;
  leadingIconClassName?: string;
  fullWidth?: boolean;
  textAlignLeft?: boolean;
  className?: string;
  textClassName?: string;
  id?: string;
  analyticsLabel?: string;
  target?: string;
  shortcut?: ShortcutDefinition;
  variant: keyof typeof variant;
  noBackground?: boolean;
  defaultAnimation?: string;
  shortcutClassName?: string;
};

export function ButtonContent(props: ButtonContentPropsType) {
  const {
    children: text,
    LeadingIcon,
    TrailingIcon,
    trailingIconClassName,
    leadingIconClassName,
    shortcut,
    fullWidth,
    textAlignLeft,
    className,
    textClassName,
    noBackground,
    shortcutClassName,
  } = props;

  const variation = allVariants.variant[props.variant];

  const btnClassName = cn(allVariants.$all, variation.buttonStyling);
  const iconClassName = variation.icon;
  const iconSpacingClassName = variation.iconSpacing;
  const shortcutStyling = variation.shortcutStyling;
  const textStylingClassName = variation.textStyling;

  return (
    <div
      className={cn(
        textAlignLeft ? "text-left" : "justify-center",
        "flex w-full items-center",
        iconSpacingClassName,
        btnClassName,
        noBackground ? "bg-transparent" : "",
        fullWidth ? "w-full" : "w-auto",
        className
      )}
    >
      {LeadingIcon && (
        <LeadingIcon
          className={cn(
            "shrink-0 justify-start transition",
            iconClassName,
            leadingIconClassName
          )}
        />
      )}

      {text &&
        (typeof text === "string" ? (
          <span
            className={cn(
              "mx-auto grow self-center truncate transition",
              textStylingClassName,
              textClassName
            )}
          >
            {text}
          </span>
        ) : (
          <div className="w-full transition">{text}</div>
        ))}

      {TrailingIcon && (
        <TrailingIcon
          className={cn(
            trailingIconClassName,
            iconClassName,
            "shrink-0 justify-end transition"
          )}
        />
      )}
      {shortcut && (
        <ShortcutKey
          className={cn(
            shortcutStyling,
            "hidden sm:inline-block",
            shortcutClassName
          )}
          shortcut={shortcut}
          variant={variation.shortcutVariant ?? "medium"}
        />
      )}
    </div>
  );
}

type ButtonPropsType = Pick<
  JSX.IntrinsicElements["button"],
  "type" | "disabled" | "onClick" | "name" | "value"
> &
  React.ComponentProps<typeof ButtonContent>;
// eslint-disable-next-line react/display-name
export const Button = forwardRef<HTMLButtonElement, ButtonPropsType>(
  ({ id, analyticsLabel, type, disabled, onClick, ...props }, ref) => {
    const innerRef = useRef<HTMLButtonElement>(null);
    useImperativeHandle(ref, () => innerRef.current as HTMLButtonElement);

    if (props.shortcut) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      useShortcutKeys({
        shortcut: props.shortcut,
        action: () => {
          if (innerRef.current) {
            innerRef.current.click();
          }
        },
        disabled,
      });
    }

    return (
      <button
        className={cn(
          "group outline-none",
          props.fullWidth ? "w-full" : "w-fit"
        )}
        type={type}
        disabled={disabled}
        onClick={onClick}
        name={props.name}
        value={props.value}
        id={id}
        ref={innerRef}
        data-action={analyticsLabel}
      >
        <ButtonContent {...props} />
      </button>
    );
  }
);

type LinkPropsType = Pick<LinkProps, "to" | "target" | "prefetch"> &
  React.ComponentProps<typeof ButtonContent>;
export const LinkButton = ({
  to,
  prefetch,
  target,
  analyticsLabel,
  ...props
}: LinkPropsType) => {
  const innerRef = useRef<HTMLAnchorElement>(null);
  if (props.shortcut) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useShortcutKeys({
      shortcut: props.shortcut,
      action: () => {
        if (innerRef.current) {
          innerRef.current.click();
        }
      },
    });
  }

  if (to.toString().startsWith("http")) {
    return (
      <ExtLink
        href={to.toString()}
        analyticsLabel={analyticsLabel}
        target={target}
        ref={innerRef}
        className={cn(
          "group outline-none",
          props.fullWidth ? "w-full" : "w-fit"
        )}
      >
        <ButtonContent {...props} />
      </ExtLink>
    );
  } else {
    return (
      <Link
        to={to}
        data-action={analyticsLabel}
        ref={innerRef}
        className={cn(
          "group outline-none",
          props.fullWidth ? "w-full" : "w-fit"
        )}
      >
        <ButtonContent {...props} />
      </Link>
    );
  }
};

type NavLinkPropsType = Pick<NavLinkProps, "to" | "target"> &
  Omit<React.ComponentProps<typeof ButtonContent>, "className"> & {
    className?: (props: {
      isActive: boolean;
      isPending: boolean;
    }) => string | undefined;
  };
export const NavLinkButton = ({
  to,
  className,

  ...props
}: NavLinkPropsType) => {
  return (
    <NavLink
      to={to}
      className={cn("group outline-none", props.fullWidth ? "w-full" : "w-fit")}
    >
      {({ isActive, isPending }) => (
        <ButtonContent
          className={className && className({ isActive, isPending })}
          {...props}
        />
      )}
    </NavLink>
  );
};

type ExtLinkProps = JSX.IntrinsicElements["a"] & {
  children: React.ReactNode;
  className?: string;
  href: string;
  analyticsLabel?: string;
};

const ExtLink = React.forwardRef<HTMLAnchorElement, ExtLinkProps>(
  ({ className, href, children, analyticsLabel, target, ...props }, ref) => {
    return (
      <a
        ref={ref}
        className={className}
        data-action={analyticsLabel}
        target={target}
        rel="noopener noreferrer"
        href={href}
        {...props}
      >
        {children}
      </a>
    );
  }
);

ExtLink.displayName = "ExtLink";
